<template>
	<div>
		<el-form :model="form" :rules="formRules" ref="form" label-position="top" status-icon>
			<el-row :gutter="50">
				<el-col :xs="24" :md="12">
					<div class="box-title">Date administrator</div>

					<el-form-item :label="$t('register.label.admin-name')" prop="name">
						<el-input v-model="form.name" :placeholder="$t('register.placeholder.admin-name')"></el-input>
					</el-form-item>

					<el-form-item :label="$t('register.label.email')" prop="email">
						<el-input v-model="form.email" :placeholder="$t('register.placeholder.admin-email')"></el-input>
					</el-form-item>

					<el-form-item :label="$t('register.label.phone')" prop="phone">
						<vue-tel-input
							v-model="phone"
							:inputOptions="{ showDialCode: true, tabindex: 0 }"
							defaultCountry="ro"
							mode="international"
							@validate="onPhoneValidate"
						>
						</vue-tel-input>
					</el-form-item>
				</el-col>

				<el-col :xs="24" :md="12">
					<div class="box-title">{{ $t('register.step.documents') }}</div>

					<template v-for="(act, index) in form.acts">
						<el-form-item
							v-if="showCui(act) || showAdminCi(act) || showCert(act) || isIndependent(act)"
							:label="act.title"
							:prop="'acts[' + index + '].document'"
							:rules="[{ required: act.required, message: $t('register.documents.error-required'), trigger: ['change', 'blur'] }]"
							:key="index">

							<el-upload
								action="#"
								:data="{ actid: act.id }"
								:http-request="uploadActFile"
								:on-remove="removeActFile"
								:limit="1"
								accept="image/jpeg, image/png, application/pdf"
								:ref="'upload_' + act.id">
								<el-button type="primary" size="small" plain class="fullWidth">
									<i class="el-icon-upload"></i> Incarca un fisier
								</el-button>
								<div v-if="act.description" slot="tip" class="document-tip">{{ act.description }}</div>
							</el-upload>
							
						</el-form-item>
					</template>

					<Box type="warning" v-if="!hasCui" :active="true">
						<div slot="box-body">
							<ul class="rawUl">
								<li v-html="$t('register.documents.cui-upload-message')"></li>
								<li v-html="$t('register.documents.cui-upload-message-2')"></li>
							</ul>
						</div>
					</Box>
					<Box type="warning" v-if="isCuiSigned && !hasAdminCI && !hasCert" :active="true">
						<div slot="box-body">
							<ul class="rawUl">
								<li v-html="$t('register.documents.cui-signed-message')"></li>
							</ul>
						</div>
					</Box>
					<Box type="warning" v-if="hasCui && !isCuiSigned && !hasAdminCI" :active="true">
						<div slot="box-body">
							<ul class="rawUl">
								<li v-html="$t('register.documents.cui-unsigned-message')"></li>
							</ul>
						</div>
					</Box>
				</el-col>
			</el-row>
		</el-form>
		<Box type="info" :active="true">
			<div slot="box-body">
				<ul class="rawUl">
					<li v-html="$t('register.documents.file-message')"></li>
					<li v-html="$t('register.documents.upload-message')"></li>
				</ul>
			</div>
		</Box>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { LocalError} from '@/utils/errorHandler'

import { VueTelInput } from 'vue-tel-input';

const phoneStatus = {
	number: '',
	status: false
};

export default {
	data() {
		const validateName = (rule, value, callback) => {
				if (/([a-zA-Z]+\s?\b){2,}/i.test(value) != true) {
					return callback(new Error(this.$t('register.error.admin-name')));
				} else {
					callback();
				}
			};
		const validatePhone = (rule, value, callback) => {
			if (!phoneStatus.status) {
				return callback(Error(this.$t('register.error.phone')));
			}
			return callback();
		};
		return {
			loading: false,
			phone: '',
			form: {
				name: '',
				email: '',
				phone: '',
				type: 'admin',
				acts: {}
			},
			formRules: {
				name: [{
					required: true,
					validator: validateName,
					trigger: ['change', 'blur'],
				}],
				email: [
					{ required: true, message: this.$t('register.error.email'), trigger: ['change', 'blur'] },
					{ type: 'email', message: this.$t('register.error.email'), trigger: ['change', 'blur'] }
				],
				phone: [{
					validator: validatePhone,
					trigger: ['change', 'blur']
				}]
			}
		}
	},
	components: {
		Box: () => import('vue-info-box-element'),
		VueTelInput
	},
	computed: {
		hasCui() {
			if (this.form.acts.length > 0) {
				let filtered = this.form.acts.filter(act => {
					if (act.id === 'cui' && act.document && act.document.status !== -1)
						return true
				})
				if (filtered.length > 0) {
					return true
				}
			}
			return false
		},
		hasAdminCI() {
			if (this.form.acts.length > 0) {
				let filtered = this.form.acts.filter(act => {
					if (act.id === 'admin_ci' && act.document && act.document.status !== -1)
						return true
				})
				if (filtered.length > 0) {
					return true
				}
			}
			return false
		},
		hasCert() {
			if (this.form.acts.length > 0) {
				let filtered = this.form.acts.filter(act => {
					if (act.id === 'certificat_constatator' && act.document && act.document.status !== -1)
						return true
				})
				if (filtered.length > 0) {
					return true
				}
			}
			return false
		},
		isCuiSigned() {
			if (this.form.acts.length > 0) {
				let filtered = this.form.acts.filter(act => {
					if (act.id === 'cui' && act.document && act.document.signed && act.document.status !== -1)
						return true
				});
				if (filtered.length > 0) {
					return true
				} 
			}
			return false
		}
	},
	methods: {
		async nextStep() {
			this.loading = true

			return new Promise((resolve, reject) => {
				this.$refs['form'].validate((valid) => {
					if (valid) {
						this.$store.dispatch('leads/SaveAffiliateContact', this.form).then(res => {
							if (!res.error) {
								this.sbMsg({
									type: 'success',
									message: 'Contul a fost inregistrat cu succes!'
								})
								resolve();
							} else {
								reject(new Error('Eroare. Incercati mai tarziu'));
							}
						}).catch(e => {
							reject(e);
						});
					} else {
						this.sbMsg({
							type: 'error',
							message: this.$t('register.documents.error-validate')
						})
						reject(new LocalError(this.$t('register.documents.error-validate')))
					}
				});
			});
		},
		async getAffiliateActs() {
			try {
				const res = await this.$store.dispatch('leads/GetAffiliateActs')
				this.form.acts = res.message
			} catch (e) {
				this.$reportToSentry(e, {
					extra: {
						fn: 'getAffiliateActs'
					}
				})
			}
		},
		uploadActFile(request) {
			const formData = new FormData()
			formData.append('file', request.file)
			formData.append('actid', request.data.actid);

			this.$store.dispatch('leads/UploadActFile', formData).then(res => {
				this.form.acts = res.message
				request.onSuccess(request.data.actid)
			}).catch(e => {
				this.$refs['upload_' + request.data.actid][0].clearFiles()
			});
		},
		removeActFile(request) {
			this.$store.dispatch('leads/RemoveActFile', { actid: request.response }).then(res => {
				this.form.acts = res.message
			}).catch(e => {
				this.$reportToSentry(e, {
					extra: {
						fn: 'removeActFile'
					}
				})
			});
		},
		onPhoneValidate(data) {
			this.form.phone = data.number;
			phoneStatus.status = data.valid;
		},
		showCui(act) {
			return act.id && act.id === 'cui'
		},
		showAdminCi(act) {
			return act.id && act.id === 'admin_ci' && this.hasCui && !this.hasCert
		},
		showCert(act) {
			return act.id && act.id === 'certificat_constatator' && this.hasCui && this.isCuiSigned && !this.hasAdminCI
		},
		isIndependent(act) {
			return act.independent
		}
	},
	created() {
		this.getAffiliateActs()
	}
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.box-title {
	font-size: 24px;
	color: #f17146;
	margin-bottom: 10px;
	margin-top: 10px;
}

.separator {
	background: #cccccc;
	border: none;
	height: 1px;
}

.document-tip {
	font-size: 12px;
    line-height: 12px;
    margin-top: 7px;
    margin-bottom: 5px;
    color: #7d7d7d;
}

.info-box {
	padding: 15px 10px;
    text-align: center;
    margin-bottom: 15px;
    background: #f7f7f7;
    border-radius: 4px;
    color: #828282;
    border: 1px #f1f1f1 solid;
}

.tip.info {
	margin-bottom: 0px;
}

::v-deep .el-upload {
	width: 100% !important;
}
</style>